import React from "react";
import "./joinUs.css";

function JoinUs() {
  return (
    <div className="i-top">
      <div className="joinUs-cntnr">
        <div className="joinUs-unit">
          <h3>Our Mission</h3>
          <p>
            The London Heritage Farm Society is a non-profit organization that
            together with the City of Richmond, preserves, restores and
            maintains the London Farm.
          </p>

          <p>The Society mission is threefold:</p>

          <div className="callout">
            <p>
              To collect historical objects associated with farming, and that
              reflect the lives of pioneer Richmond farm families, and the
              London family in particular, during the period 1880 to the 1940s.
            </p>
            <p>
              To provide visitors the opportunity to discover how the early
              Richmond pioneer farmers contributed to the development of
              Richmond.
            </p>
            <p>To preserve London Farm.</p>
          </div>
        </div>
        <div className="joinUs-unit">
          <div>
            <h3>Membership</h3>
            <p>Come join us at London Farm!</p>
            <div>
              <ul className="joinUs-gallery">
                <li className="joinUs-img">
                  <img
                    className="respnsiv-img"
                    src={process.env.PUBLIC_URL + "lhfjoinb1.png"}
                    alt=""
                  ></img>
                </li>
                <li className="joinUs-img">
                  <img
                    className="respnsiv-img"
                    src={process.env.PUBLIC_URL + "volunteers2b.png"}
                    alt=""
                  ></img>
                </li>
                <li className="joinUs-img">
                  <img
                    className="respnsiv-img"
                    src={process.env.PUBLIC_URL + "blackberries.png"}
                    alt=""
                  ></img>
                </li>
                <li className="joinUs-img">
                  <img
                    className="respnsiv-img"
                    src={process.env.PUBLIC_URL + "pink.png"}
                    alt=""
                  ></img>
                </li>
              </ul>
            </div>
            <p>
              Are you interested in gardening? Perhaps you are curious about how
              to keep bees or chickens? How about discovering organic gardening
              from master gardeners, or an opportunity to practice organic
              gardening? All this and more is available to members of London
              Heritage Farm Society.
            </p>
            <p>
              London Heritage Farm Society is actively seeking new members who
              would like to support our mandate to preserve and celebrate the
              agricultural history of Richmond.
            </p>
            <p>
              London Farm is a heritage farm house and pastoral property located
              in Richmond on the scenic south arm of the Fraser River, and is
              sought after by Richmond residents and visitors alike as a quiet
              destination to enjoy gardens, nature, and history. It is a public
              space open to all and provides a beautiful outdoor setting to
              enjoy a quiet walk meandering through its pathways or to host a
              special event. It is a popular destination for amateur
              photographers with an abundance of flowers and birds. Families and
              children love to visit the farm whether it is to attend a
              farm-sponsored event or just to drop by and enjoy the serenity and
              cultural ambiance.
            </p>
            <p>
              The Society offers plenty of volunteer opportunities for those who
              value community involvement, and an opportunity to meet others who
              share a similar passion. Members may optionally elect to sign up
              for a garden allotment as they become available.
            </p>
            <p>
              Membership fees are $25 for a single membership, $35 for a family
              and $75 for a business. For more information,email us at
              londonhf@telus.net or use the Contact Us form on the Plan Your
              Visit page.
            </p>
            <p>
              To become a member, please download, print and complete the
              Membership Form below, and submit it by email to
              londonhf@telus.net. Or you may drop by London Farm during our open
              hours in person to obtain and submit the Membership Form.
            </p>
          </div>
          <div className="membershipDownload">
            <a
              href={process.env.PUBLIC_URL + " lhfsmembership2023.pdf"}
              download
            >
              Click here to Download
            </a>
          </div>
          <div>
            <img
              className="respnsiv-img border-green"
              src={process.env.PUBLIC_URL + "lhfsmembership2023.png"}
              alt=""
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinUs;
