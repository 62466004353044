import React, { useState } from "react";
import Products from "./Products";
//import CategoryMenu from "./CategoryMenu";
import ProdDetails from "./ProdDetails";
import "./store.css";


function StoreCntnr(props) {

  //const [dsplyProds, setDsplyProds] = useState(true);
  const [selectedProd, setSelectedProd] = useState();
  //const [category, setCategory] = useState('Food');
  const [toggler, setToggler] = useState(true);


  /*function chgCategory(catgry) {
		setCategory(catgry);
		console.log('chcategory' + category);
	}*/


  function prodDetails (details) {
    console.log('arrived at prodDetails');
    console.log(details);
    setSelectedProd(details);
    setToggler(toggler => !toggler);
  }
  function returnToProds () {
    console.log('arrived at returnToProds');
    setToggler(toggler => !toggler);
  }
  function addToCartSuccess  () {
    console.log("here we are at addtocart!");
    setToggler(toggler => !toggler);
  }

  /*<div className="storeFlexBanner">
				<div className="bannerItem">
					<h2>HERE IS THE BANNER</h2>
				</div>
			</div>*/
  /*					<CategoryMenu chgCategory={chgCategory} />
*/
  return (
    <div className="">
      <h1>Gift Shop</h1>
      <div className="storTop">
      </div>
      <div className="storeProdArea">
        {toggler
          ? <Products prodDetails={prodDetails} />
          : <ProdDetails {...props}
            selected={selectedProd}
            returnToProds={returnToProds}
            addToCartSuccess={addToCartSuccess} />
        }
      </div>
    </div>
  );
}
export default StoreCntnr;