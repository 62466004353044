import React from "react";
import ReactPlayer from 'react-player/youtube';

function ExpHistory () {

  return(
    <div className="expArticle">
      <div>
        <p className="expTitle">A Brief History of the London Farm</p>
      </div>
      <div>
        <div className="player-wrapper">

          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=m7LJ9NYXNGk"
            width='100%'
            height='100%'
            light={false}
            controls={true}
          />
        </div>
        <div className="expContent">
          <p className="expSubHdng">The London family</p>
          <p>Charles Edwin London, aged 16, and his brother William, aged 17, arrived in British Columbia from Ontario in 1877.  Three years later, the brothers purchased 200 acres of land for $2000, erected a small farmhouse and began clearing and draining the land in preparation for farming. In 1888, Charles married Henrietta Dalzeil of Dalbeattie, Scotland, and started building the farmhouse that still stands today.</p>
          <p>The farm produced dairy products, oats, wheat barley, fruit from an orchard and vegetables. There were many outbuildings on site including storage sheds for dairy, preserves, salted salmon and pork. The property had a water tower, a shack for farm workers and barns for animals, hay, wagons and buggies. The London’s established a general store and post office and built a wharf to receive supplies and to ship their milk and produced to New Westminister.</p>
          <p>Charles and Henrietta London had eight children, of whom three died in infancy as was common in those days. Henrietta died in 1916 and the family stayed at London Farm only another three years before selling up and moving to the Marpole area of Vancouver.  However, in 1921 the  eldest daughter Lucy and her husband Herbert Howse, bought the farm back.  They farmed and raise their family at London Farm until 1948. Thereafter, the Farmhouse was rented by a series of families until its purchase by the City of Richmond in 1978 and the farm was designated a municipal heritage site.</p>

        </div>
      </div>
    </div>
  );
}
export default ExpHistory;