import React from "react";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
//import "./carousel.css";
import {Carousel} from 'react-responsive-carousel';

function ExpPond () {
  /*
	<figure>
						<img className="exp-img-responsive" src={process.env.PUBLIC_URL + "pond1.jpg"} alt="pond"></img>

						<figcaption>The pond in early spring.</figcaption>
					</figure>
					*/

  return(
    <div className="expArticle">
      <div>
        <p className="expTitle">The Pond –  A Wildlife Habitat</p>
      </div>
      <div>
        <div className="expCarousel">

          <Carousel
            showIndicators={true}
            infiniteLoop={true}
            showThumbs={false}
            showArrows={true}
            useKeyboardArrows={true}
          >
            <div>
              <img src={process.env.PUBLIC_URL + "pond2.jpg"} alt="" />
              <p className="legend">The pond in spring</p>
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + "pond5.jpg"} alt="" />
              <p className="legend">The pond in early summer</p>
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + "pond3.jpg"} alt="" />
              <p className="legend">Bullrushes thriving in summer</p>
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + "pond4.jpg"} alt="" />
              <p className="legend">the pond covered with algae in hot summer</p>
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + "pond1.jpg"} alt="" />
              <p className="legend">The pond in the fall</p>
            </div>
          </Carousel>
        </div>
        <div className="expContent">
          <p>In the centre of London Farm just west of the caretaker’s house, tall trees and natural flora surround a tranquil pond where various wildlife make their homes. In the days of the London family, the area was originally a slough and a habitat for domestic geese.</p>

          <p>Over time, work has been done to retain water that used to flow into the Dyke Road drainage canal, creating a pond and wetland.  Lush natural vegetation is now established all around the pond area.  Where raspberry canes once grew next to the slough, now salmonberries and blackberry bushes grow naturally around the water’s edge.  While wild roses once adorned the dyke fence, now wildflowers and bulrushes grow along the edge of the pond along Dyke Road.</p>


          <p>A variety of waterfowl live in the pond and wetland, using the natural vegetation and water for nesting and raising their young.  While taking a stroll along the path by the pond, visitors can hear not only the rooster crowing from the chicken coop, but also wild geese honking and bullfrogs croaking in the marshes. Mallard ducks and wild geese swim around the many native plants and bulrushes that help to protect the pond inhabitants from prey and onlookers.  Songbirds call and chirp from the trees above, and dragonflies flit about the reeds. And occasionally, Eagles can be seen sitting in the trees waiting for unsuspecting prey.</p>
          <p>Future plans include continuation of the path that will lead to a small bridge over the pond to enjoy the tranquility and beauty of nature’s work from another perspective.</p>

          <p>The London Heritage Farm Society is committed to maintaining London Heritage Farm as a place to enjoy open spaces and experience nature as part of life on a farm.  Visit the pond for a natural peaceful location to appreciate nature.</p>
          <p>Please do not feed the wildlife as human food is not healthy for them and can encourage them to abandon their natural feeding habits.</p>
        </div>
      </div>
    </div>
  );
}
export default ExpPond;