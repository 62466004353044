import React from "react";
import "./explore.css";
/*import lhfmap from "../img/lhf-map.png";*/
import farmhouse from "../img/explore/farmhouse200x113.jpg";
import heritagegardens from "../img/explore/heritagegardensSm.jpg";
import history from "../img/historyexp.jpg";
import chickens from "../img/explore/chickens3.jpg";
import  pond from "../img/explore/pond200x113.jpg";
import  allotments from "../img/explore/allot200x113.jpg";




function ExploreMenu (props) {
  return (
    <div>
      <ul className="expMenu">
        <li className="expMenuItems" style={{
          backgroundImage:
					`linear-gradient(
						rgba(0,0,0, 0.2),
						rgba(0,0,0, 0.2)
					  ), url(${farmhouse})`
        }}
        onClick={() => props.chgComponent('FarmHouse')}><i className="iDescript">Farm House</i>
        </li>
        <li className="expMenuItems" style={{
          backgroundImage:
						`linear-gradient(
						rgba(0,0,0, 0.2),
						rgba(0,0,0, 0.2)
					  ), url(${history})`
        }}
        onClick={() => props.chgComponent('History')}><i className="iDescript"> History</i>
        </li>
        <li className="expMenuItems" style={{
          backgroundImage:
						`linear-gradient(
						rgba(0,0,0, 0.2),
						rgba(0,0,0, 0.2)
					  ), url(${heritagegardens})`
        }}
        onClick={() => props.chgComponent('HeritageGardens')}><i className="iDescript">Heritage Gardens</i>
        </li>
        <li className="expMenuItems" style={{
          backgroundImage:
						`linear-gradient(
						rgba(0,0,0, 0.2),
						rgba(0,0,0, 0.2)
					  ), url(${chickens})`
        }}
        onClick={() => props.chgComponent('Chickens')}><i className="iDescript">Chickens</i>
        </li>
        <li className="expMenuItems" style={{
          backgroundImage:
						`linear-gradient(
						rgba(0,100,0, 0.2),
						rgba(0,100,0, 0.2)
					  ), url(${pond})`
        }}
        onClick={() => props.chgComponent('Pond')}><i className="iDescript">The Pond</i>
        </li>
        <li className="expMenuItems" style={{
          backgroundImage:
						`linear-gradient(
						rgba(0,100,0, 0.3),
						rgba(0,100,0, 0.3)
					  ), url(${allotments})`
        }}
        onClick={() => props.chgComponent('Allotments')}><i className="iDescript">Allotments</i>
        </li>
      </ul>

    </div>
  );
}
export default ExploreMenu;