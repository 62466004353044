import React from "react";
import "./modal.css";

function Modal({ setOpenModal, modalPhoto, modalImage, modalDtls }) {
  console.log(modalPhoto);
  console.log(modalImage);
  console.log(modalDtls);

  return (
    <div className="modalBackground i-top">
      <button
        className="titleCloseBtn"
        onClick={() => {
          setOpenModal(false);
        }}
      >
        X
      </button>
      <div className="modalContainer">
        <div className="modalImg">
          <img
            className="respModal"
            src={process.env.PUBLIC_URL + "/gallery/" + modalImage}
          ></img>
        </div>
        <div className="modalInfo">
          <div className="modalHdng">
            <p>{modalPhoto}</p>
          </div>
          <div className="modalDtls">
            <p>{modalDtls}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Modal;
