import React from "react";
import "./weddings.css";

const optLocations = [
  {
    id: "01",
    location: "The Lavender Garden",
    image: "lavendergardensmbr.jpg",
    description: `In the Lavender Garden area of the West Lawn - 
    up to 100 guests`,
  },
  {
    id: "02",
    location: "The Spraggs Barn",
    image: "barnExtnd1.png",
    description: `In front of the Spraggs Barn, for smaller intimate wedding ceremonies`,
  },
  {
    id: "03",
    location: "The Pond Bridge",
    image: "pondbridgesm.jpg",
    description: `At the West Lawn side of the bridge, for smaller intimate wedding ceremonies`,
  },
];
const optLctns = optLocations.map((locations) => (
  <li key={locations.id}>
    <div className="wdngsAltCol">
      <div>
        <p
          className="wdngsTextStd"
          style={{
            width: "320px",
            fontSize: "1.5rem",
            fontWeight: "600",
            margin: "5px 10px 0px 10px",
          }}
        >
          {locations.location}
        </p>
        <img
          className="liAltLctns"
          src={process.env.PUBLIC_URL + "/weddings/" + locations.image}
          alt=""
        ></img>
      </div>
      <div
        className="wdngsTextStd"
        style={{
          width: "320px",
          fontSize: "1.3rem",
          margin: "0px 10px 0px 0px",
        }}
      >
        <p>{locations.description}</p>
      </div>
    </div>
  </li>
));

/*console.log(optLctns[1].description);*/
function Weddings() {
  /*style={{ fontFamily: "Rochester", color:"black" }}*/
  /*style={{flexDirection:"column-reverse"}} */
  /*<ul className="UlAltLctns">
          {optLctns}
        </ul>*/
  /*<p className="wdngsTextStd" style={{padding:"10px",margin:"10px 20px 20px 20px", textAlign:"center", width:"auto", backgroundColor:"rgb(101,141,27,.1)"}}>If you would like to consider other options, we offer the following locations:</p> */
  /*<p>{locations.details}</p>*/

  return (
    <div style={{ margin: "100px 20px 0px 20px" }}>
      <div className="wdngs-cntnr">
        <div className="wdngs-flexCol">
          <p className="h3Weddings">London Farm Weddings</p>
          <p className="wdngsTextStd">
            London Farm is a unique yet popular venue for weddings in a
            park-like setting. From a small intimate wedding ceremony to large
            scale reception, London Farm offers many options that will make your
            wedding truly special and unforgettable.
          </p>
        </div>
        <div className="wdngs-flexCol">
          <img
            className="respnsiv-img"
            src={process.env.PUBLIC_URL + "/weddings/" + "wdng1.png"}
            alt=""
          ></img>
        </div>
      </div>
      <div className="wdngs-cntnr-rvrs">
        <div className="wdngs-flexCol">
          <img
            className=""
            src={process.env.PUBLIC_URL + "/weddings/" + "gazeboMed.jpg"}
            alt=""
          ></img>
        </div>
        <div className="wdngs-flexCol">
          <p className="h3Weddings">Ceremony</p>
          <p className="wdngsTextStd">
            The large gazebo in the spacious south lawn is the best location for
            your ceremony. The Gazebo is tucked away in a corner and feels very
            peaceful and inviting. The south lawn setting can cater to any idea
            or theme you would like for your wedding.
          </p>
        </div>
      </div>
      <div className="wdngs-cntnr">
        <div className="wdngs-flexCol">
          <p className="h3Weddings">Ceremony Setting</p>
          <p className="wdngsTextStd">
            For example, seating and canopies can be set up facing the gazebo,
            and family members and guests can witness the delightful and solemn
            moments of the wedding Ceremony.
          </p>
        </div>
        <div className="wdngs-flexCol">
          <img
            className=""
            src={process.env.PUBLIC_URL + "/weddings/" + "chairs.png"}
            alt=""
          ></img>
        </div>
      </div>
      <div className="wdngs-cntnr-rvrs">
        <div className="wdngs-flexCol">
          <img
            className=""
            src={process.env.PUBLIC_URL + "/weddings/" + "pdf3.png"}
            alt=""
          ></img>
        </div>
        <div className="wdngs-flexCol">
          <p className="h3Weddings">Reception</p>
          <p className="wdngsTextStd">
            Formal banquet, lawn picnic or BBQ are all popular options available
            at London Farm.
          </p>
          <p className="wdngsTextStd">
            Dining tables and chairs, coffee and tea stations and bars can be
            setup in the south and west lawns for up to 250 guests to celebrate
            with the newlywed couple in a formal lunch or dinner.
          </p>
          <p className="wdngsTextStd">
            Country style wooden picnic tables and benches in a park-like
            setting are great alternatives to a formal banquet in the
            summertime.
          </p>
          <p className="wdngsTextStd">
            {" "}
            Guests can also enjoy the Heritage Gardens and walking the trails
            with info-stations along the way describing life on the farm in the
            early 1900s.
          </p>
        </div>
      </div>
      <div className="wdng-Lctns">
        <p className="wdngflexctr">Location Options</p>
      </div>
      <div className="wdngs-cntnr">{optLctns}</div>
      <div className="wdngs-cntnr">
        <div
          className="wdngs-flexCol"
          style={{
            backgroundColor: "rgb(101,141,27,.2)",
            margin: "20px 0px 0px 0px",
            padding: "0px 0px 20px 10px",
          }}
        >
          <p className="h3Weddings">Rentals</p>
          <p className="wdngs-rentals2">
            <span style={{ fontWeight: "bold" }}>DEPOSIT:</span> A 50%
            non-refundable deposit of the site rental cost is required to
            reserve the booking.
          </p>
          <p className="wdngs-rentals">Site Rental of Gazebo and South Lawn:</p>
          <i className="wdngs-rentals2">
            $1,000.00 for the first 2 hours and $175.00 per hour thereafter.
          </i>
          <i className="wdngs-rentals">Site Rental of Optional locations:</i>
          <i className="wdngs-rentals2">
            The Lavender Garden: $500.00 for the first 2 hours and $100.00 per
            hour thereafter.
          </i>
          <i className="wdngs-rentals2" style={{ marginTop: "-10px" }}>
            Spraggs Barn or Pond Bridge: $150.00 for 3 hours.
          </i>
          <p className="wdngs-rentals">Table, Chair and Tent Rentals:</p>
          <p className="wdngs-rentals2">
            Note:You may rent tables,chairs and tents from the London Farm at
            the rates below (setup and removal included), or your may rent
            elsewhere (in which case you will be responsible for setup and
            removal).
          </p>
          <p className="wdngs-rentals2">Chair Rentals: $2.50 per chair</p>
          <p className="wdngs-rentals2">
            Round or Banquet Tables (3ft by 8ft): $15.00 per table{" "}
          </p>
          <p className="wdngs-rentals2">
            Tent Rental 10ft x 10ft: $60.00 each{" "}
          </p>
          <p className="wdngs-rentals2">
            Tent Rental 10ft x 20ft: $90.00 each{" "}
          </p>
        </div>
        <div
          className="wdngs-flexCol"
          style={{ backgroundColor: "rgb(101,141,27,.2)", marginTop: "20px" }}
        >
          <p className="h3Weddings">General Info</p>
          <p className="wdngs-rentals">Use of the London Farm House:</p>
          <p className="wdngs-rentals2">
            If you would like to consider using the Farm House in any way,
            please contact us to discuss.
          </p>
          <p className="wdngs-rentals">Insurance:</p>
          <p className="wdngs-rentals2">
            Users shall provide and pay for their own insurance coverage for
            damage, liability, theft and fire (including for any items brought
            onto the premises or site).
          </p>
          <p className="wdngs-rentals">Food and Beverage:</p>
          <p className="wdngs-rentals2">
            You may arrange for food service from businesses who meet BC food
            safety requirements as well as Food Handler Certification. If you
            will be serving alcohol you are required to have a Special Event
            liquor permit.
          </p>

          <p className="wdngs-rentals">Parking:</p>
          <p className="wdngs-rentals2">
            Parking is available along the Fraser River on Dyke Road opposite
            London Farm.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Weddings;
