import React, { useState, useEffect } from "react";
import "./donations.css";
/*src={process.env.PUBLIC_URL + ".png"}*/
/*        {message ? <p>{message}</p> : <ProductDisplay />}*/

const ProductDisplay = () => (
  <section>
    <div className="product">
      <img
        className="logo"
        src={process.env.PUBLIC_URL + "/londonFarmLogo.png"}
        alt="Donations"
      ></img>

      <div className="productDescription">
        <p>Donation to London Heritage Farm Society</p>
      </div>
      <div>
        <form
          action="http://localhost:4242/create-checkout-session"
          method="POST"
        >
          <button className="donationButton" type="submit">
            Checkout - enter amount and pay
          </button>
        </form>
      </div>
    </div>
  </section>
);

export default function Donations() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage(
        "Thank you for your Donation, and we have sent you a receipt via email.If you have any questions please contact us at 604-271-5220."
      );
    }

    if (query.get("canceled")) {
      setMessage(
        "Donation canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);
  /*<section className="donatInfo">
        <div className="benefit">
          <p>
            The London Heritage Farm Society is a registered charitable
            organization that together with the city of Richmond preserves,
            restores and maintains the London Farm. We hold events throughout
            the year to share our knowledge of the early farming days in
            Richmond. This includes Family Farm Day, Plant Sales,Presentations
            on Farming practices such as vegetable gardening and bee-keeping.
          </p>
        </div>
      </section>
	  */
  /*        <i className="donatImgs">{dImg.photo}</i>*/
  /*<div className="donatCollage">
        <img
          className="hdrImages"
          src={process.env.PUBLIC_URL + "/donations/" + dImg.photoImg}
        ></img>
        <i className="photoDetails">{dImg.details}</i>
      </div>*/

  /* const collage = [
    {
      id: "01",
      photoImg: "threshingv2.png",
      details: `life on the farm`,
    },
    {
      id: "02",
      photoImg: "ponyRidev2.png",
      details: `Pony rides-Family Farm Day`,
    },
    {
      id: "03",
      photoImg: "chickensv2.png",
      details: `london farm chickens`,
    },
    { id: "04", photoImg: "barnv2.png", details: `Weddng party at the barn` },
    {
      id: "05",
      photoImg: "honeyBees.png",
      details: `busy honey bees`,
    },
    {
      id: "06",
      photoImg: "beautifulGardens.png",
      details: `dazzling displays of flowers, vegetables and fruit`,
    },
  ];
  const listPhotos = collage.map((dImg) => (
    <li key={dImg.id}>
      <div className="collageItem">
        <img
          className="hdrImages"
          src={process.env.PUBLIC_URL + "/donations/" + dImg.photoImg}
        ></img>
        <i className="photoText">{dImg.details}</i>
      </div>
    </li>
  ));*/
  return (
    <div className="donationsFlexCntnr">
      <div className="donatIntro">
        <div className="benefits"></div>
        <div>{/*<ul className="collageFlex">{listPhotos}</ul>*/}</div>
      </div>
      <div className="donatBtn">
        <div className="condMsgOrDsply">
          {message ? (
            <div className="msgBox">
              <p>{message}</p>
            </div>
          ) : (
            <ProductDisplay />
          )}
        </div>
      </div>
    </div>
  );
}
