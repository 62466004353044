import React from "react";
import farmhouse from "../img/farmhouse2021.jpg";

function ExpFarmhouse () {

  return(
    <div className="expArticle">
      <div>
        <p className="expTitle">The London Farmhouse</p>
      </div>
      <div>
        <div>
          <figure>
            <img className="exp-img-responsive" src={farmhouse} alt="farmhouse"></img>

            <figcaption>
					The farmhouse has been recently extensively renovated while maintaining its authenticity.
            </figcaption>
          </figure>
        </div>
        <div className="expContent">
          <p>Charles and Henrietta London built a small farm house on the site in 1898, and as their home grew to accommodate their eight children, relatives and farm workers, the large white farm house was built in 1906.</p>
          <p>Guests can explore the Victorian farm house that has been fully restored and furnished to illustrate rural life in Richmond between 1890 and 1920.</p>
          <p>London Farmhouse has been furnished with articles commonly found in homes of its era. On the upper floor there are six rooms displaying the furniture, pictures, clothing, quilts and everyday articles of the era. As well, the many London family photographs that hang throughout the house give you a glimpse into their history as one of the pioneer farming families of Richmond.</p>
        </div>
      </div>
    </div>
  );
}
export default ExpFarmhouse;