import React from "react";

function ContactUsDone() {

  return (

    <div className="contactDoneCntnr">
      <div className="contactDone">
        <p>Thanks for contacting us - if you have a question, we will get back to you shortly.
        </p>
      </div>
    </div>
  );
}
export default ContactUsDone;