import React, { useState } from "react";
import ContactUs from "./ContactUs";
import ContactUsDone from "./ContactUsDone";

function ContactCntrlr (props) {
  const [ContactFormEnd, setContactFormEnd] = useState(false);

  const ContactUsEndForm = () => {
    setContactFormEnd(true);
    console.log(ContactFormEnd);
  };

  return(
    <div style={{marginTop: '10px'}}>
      <div>
        {ContactFormEnd ? (
          <ContactUsDone />
        ) : (
          <ContactUs {...props} endContactUs={ContactUsEndForm} />
        )}
      </div>
    </div>
  );
}
export default ContactCntrlr;