import React from "react";
//import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import "../index.css";
import "./events.css";
//import { Link } from "react-router-dom";

function Events() {
  /*<div className="ffdRow">
        <div className="eventCol">
          <img
            className="eventImg"
            style={{ marginBottom: "0px" }}
            src={process.env.PUBLIC_URL + "/events/" + "LFCF_poster2a.png"}
            alt="Johnny Cash event"
          ></img>
        </div>
      </div>*/
  /* https://stevestonheritage.ca/event/jingle-bell-high-tea-2/{" "}*/
  /*<Link
              className="teaBooking"
              style={{ fontWeight: "bold", fontSize: "2rem", color: "white" }}
              to={{
                pathname:
                  "https://docs.google.com/forms/d/e/1FAIpQLScoLObtSLDYYP7blWXjBwRSuq9kTacunWgCzgD-dvujBAIOZw/viewform",
              }}
              target="_blank"
            >
              Click here to open your booking request
            </Link>*/

  return (
    <div className="i-top">
      <div className="ffdRow">
        <div className="eventCol">
          <img
            className="eventImg"
            style={{ marginBottom: "0px" }}
            src={process.env.PUBLIC_URL + "/events/" + "1.jpg"}
            alt="Jingle Bell Tea Service"
          ></img>
          <img
            className="eventImg"
            style={{ marginBottom: "0px" }}
            src={process.env.PUBLIC_URL + "/events/" + "2.jpg"}
            alt="Jingle Bell Tea Service"
          ></img>
          <img
            className="eventImg"
            style={{ marginBottom: "0px" }}
            src={process.env.PUBLIC_URL + "/events/" + "3.jpg"}
            alt="Jingle Bell Tea Service"
          ></img>

          <div style={{ background: "green", color: "white" }}>
            <p className="teaBooking">
              Please advise requested seating time(s) and number of people. The
              tearoom manager will reach out to you to confirm your reservation.
              *Reservations are not guaranteed until you receive confirmation.
            </p>
            <p className="teaBooking">$34.99 per person / $32.99 per Senior</p>
            <p className="teaBooking" style={{ color: "white" }}>
              <a
                style={{ color: "white", fontWeight: "bold" }}
                href="https://docs.google.com/forms/d/e/1FAIpQLScoLObtSLDYYP7blWXjBwRSuq9kTacunWgCzgD-dvujBAIOZw/viewform"
                target="_blank"
                rel="noreferrer"
              >
                Click here to open your booking request
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
