
/* Component ProdDetails*/
import React from "react";
//import React, { useState } from "react"; To be used when state required

//import { FaProductHunt } from "react-icons/fa";
//import { useNavigate } from "react-router-dom";
import NumberFormat from 'react-number-format';

function ProdDetails(props) {
  //const [displayToggle, setDisplay] = useState(false);
  //const [qtyChg, setQtyChg] = useState(1);
  //localStorage.removeItem('cartItems');
  // const cartItems = [];
  //const cartItems = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
  // console.log(cartItems);
  //const navigate = useNavigate();

  /*const qtyChange = (qty) => {
    console.log("qty changed");
    console.log('qty: ' + qty);
    setQtyChg(qty);
  };*/
  /*function backToProps  () {
		console.log('atbackTo');
		props.returnToProds;
		console.log(props.returnToProds);

	};*/

  /*const addToCart = () => {
    console.log('ADD TO CART LOGIC');
    //no cart in localstorage
    if (cartItems.length < 1) {
      cartItems.push(props.selected);
      console.log(cartItems);
      localStorage.setItem('cart', JSON.stringify(cartItems));
      {navigate('/cart');}
    }

    // duplicate id check required in proddetail - to prevent duplicates in shopping cart
    // check for duplicate id(product)
    let idx = 0;
    for (idx = 0; idx < cartItems.length; idx++) {
      //console.log(selected);
      if (props.selected.id === cartItems[idx].id) {
        cartItems[idx] = props.selected;
        cartItems[idx].qty = qtyChg;
        localStorage.setItem('cart', JSON.stringify(cartItems));
        console.log('duplicate id');
        return;
      } else {
        // add to cart - needs to be uncommented for shopping cart
        const cartItems = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
        console.log(cartItems);
        console.log('add to cart');
        console.log('qty: ' + qtyChg);
        let tempSlct = props.selected;
        tempSlct.qty = qtyChg;
        cartItems.push(tempSlct);
        console.log(cartItems);
        localStorage.setItem('cart', JSON.stringify(cartItems));
        console.log('cartitems: ' + cartItems.length);
        //props.addToCartSuccess();
        //{navigate('/cart')};
      }
    }
  };*/

  return (
    <div className="prodDetFlex">
      {/*<div>
        <i className="msgToCart" style={{display: displayToggle  ? 'inline' : 'none'}}
        >Sent to Cart</i>
  </div>*/}
      <div className="prodDetPanel1">
        <div>
          <i className="productId">{props.selected.id}</i>
          <img className="img-ctr" src={process.env.PUBLIC_URL + '/store/'+ props.selected.prodImg} alt="product"></img>
        </div>
      </div>
      <div className="prodDetPanel2">
        <p className="prodDetName">{props.selected.name}</p>
        {/*<div className="prodDetails"> */}
        <p className="prodDetails">{props.selected.details}</p>
        {/*</div>*/}
        {/*<div className="prodDetQty">
					<i className="detQtyTitle">Qty:</i>
					<select className="cartQtyAmnt" onChange={(e) => qtyChange(e.target.value)}>
						<option value= "1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>
						<option value="5">5</option>
					</select>
					</div> */}

        <div className="detPrice">
          <i className="">Price:</i>
          <NumberFormat value={props.selected.price} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}
            fixedDecimalScale={true}
            className="price" />
        </div>
        <div className="detPickupNote">
          <p>Note: Customers may purchase all Gift Shop items in person, or by telephone (604-271-5220) during normal hours of operation (and arranging for pickup). </p>
          {/*<p>NOTE: All items purchased on londonfarm.ca are PICK-UP Only, and will be available at London Farm on the next Friday, Saturday, or Sunday between the hours of 10:00 am and 4:00 pm.</p>*/}
        </div>
        <div>
          <i className="prodDetSubmit"
            onClick = {props.returnToProds}>
						Return to Products</i>
        </div>
        {/*<div> THIS IS COMMENTED OUT UNTIL SHOPPING CART IS REQUIRED
					<i className="prodDetSubmit" onClick={(e) => addToCart()}
					> Add To Cart</i>
</div> */}
      </div>
    </div>

  );
}
export default ProdDetails;