import React from "react";
import "./support.css";
/*import FaqMap from "./FaqMap";*/
import About from "./About";
import ContactCntrlr from "./ContactCntrlr";
function Support() {

  return (
    <div className="supportCntnr1 i-top">
      <div className="aboutFlex">
        <h1>Plan Your Visit</h1>
        <About />
      </div>
      <div className="unitContact">
        <h1>Contact Us</h1>
        <ContactCntrlr />
      </div>
    </div>
  );
}

export default Support;