import React from "react";
//import ReactPlayer from 'react-player/youtube';
import {Carousel} from 'react-responsive-carousel';


function ExpAllotments () {

  return(
    <div className="expArticle">
      <div>
        <p className="expTitle">The Allotments – Garden plots</p>
      </div>
      <div>
        <div className="expCarousel">

          <Carousel
            showIndicators={true}
            infiniteLoop={true}
            showThumbs={false}
            showArrows={true}
            useKeyboardArrows={true}
          >
            <div>
              <img src={process.env.PUBLIC_URL + "allot241x.jpg"} alt="" />
              <p className="legend">The Welcome Garden</p>
            </div>

            <div>
              <img src={process.env.PUBLIC_URL + "allot047x.jpg"} alt="" />
              <p className="legend">North allotments</p>
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + "allot649x.jpg"} alt="" />
              <p className="legend">Western allotments</p>
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + "allot686x.jpg"} alt="" />
              <p className="legend">Tool shed and plum tree</p>
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + "allot676x.jpg"} alt="" />
              <p className="legend">Blooming Daisies</p>
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + "allot528x.jpg"} alt="" />
              <p className="legend">Full bloom</p>
            </div>

          </Carousel>
        </div>
        <div className="expContent">
          <p>Welcome to our allotment gardens!</p>
          <p>To the west of the farmhouse, past the pond and fruit trees, an open field is surrounded by an array of vegetable and flower gardens. These are individual garden plots that are tended by a community of London Heritage Farm Society members who grow vegetables and flowers throughout the year for their own use.</p>

          <p>While the London family farm originally included a much larger farming area, a walk through these gardens reminds visitors of the agricultural heritage of the land. Although the exact varieties of garden plants that were grown on the farm by the London family are not certain, crops such as carrots, potatoes, onions, beets, turnips, corn, peas, cabbage, beans and pumpkins may be considered typical for vegetables grown at London Farms in those days. Potatoes were one of the crops that the London family marketed, and cabbages grown at the farm were used to make sauerkraut for the family to keep for the winter. Here in these gardens now, gardeners grow those same types of vegetables as well as many other interesting vegetables and flowers that are available today.</p>

          <p>London Heritage Farm Society members use sustainable gardening practices and maintain the garden area to be an attractive enjoyable space for both gardeners and visitors. The silt loam soil at the farm combined with composted manure and other organic material provides a rich medium for growing produce as it did for the London family years ago. Having good rich soil is the most important factor for sustainable gardening and growing healthy strong plants.</p>

          <p>Organic gardening is practiced at the farm without the use of pesticides or herbicides. For example, visitors may see apples enclosed in sheer cloth bags to protect them from pests. Tidy gardens with regular weeding are encouraged to minimize unwanted seeds and roots from invading neighbouring garden plots. Pollinator plants are encouraged to attract bees and other beneficial insects to the gardens to minimize the impact of unwanted pests. The Welcome Garden at the entrance to these gardens displays many pollinator flowers and herbs - the phlox is especially vibrant and colourful in the summer, not only to visitors but also to the bees. A lavender garden has been planted in in the field adjacent to the gardens to further entice pollinators and beneficial insects to visit the gardens. By not using chemicals to alter nature’s work, it is hoped that the farm will continue to provide healthy produce for years to come.</p>

          <p>Fruits and vegetables have been farmed at the London Farm for over a hundred years. Our little gardens are a reminder of that heritage.</p>


        </div>
      </div>
    </div>
  );
}
export default ExpAllotments;