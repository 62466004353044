/* eslint-disable indent */
import React, { useState } from "react";
import Modal from "./Modal.js";
import "./gallery.css";

const photocontest = [
  {
    id: "01",
    photo: "BRIDGE",
    photoImg: "winnerBridge.JPG",
    photographer: "Tony Leung",
    details: `Winner of the Summer Photo Contest`,
  },
  {
    id: "02",
    photo: "LONDON FARM HOUSE AND TRACTOR",
    photoImg: "springwinner.jpg",
    photographer: "Geoff McDonell",
    details: `Winner of the Spring on the Farm Contest`,
  },
];
const photos = [
  {
    id: "01",
    photo: "HONEY BEE HYACINTH",
    photoImg: "HoneyBeeHyacinthGMCD.jpg",
    photographer: "Geoff McDonnell",
    details: `Honey Bee Visiting Hyacinth`,
  },
  {
    id: "02",
    photo: "HONEY BEES WELCOMING SPRING",
    photoImg: "HoneyBees02Apr12_2023.jpg",
    photographer: "Geoff McDonnell",
    details: `London Farm Bees are waking up and eager to collect pollen`,
  },
  {
    id: "03",
    photo: "AMERICAN WIDGEON",
    photoImg: "AmericanWidgeonGMCD.jpg",
    photographer: "Geoff McDonnell",
    details: `American Widgeon is Enjoying the Spring Sun on London Farm`,
  },
];
const beeswarm = [
  {
    id: "01",
    photo: "Bee Swarm:",
    photoImg: "bswarm01a.JPG",
    photographer: "Shelley Niemek",
    details: `As a natural means of reproduction, Honey Bee colonies split into 2 colonies. 1 colony stays in the currrent hive, and the other leaves to search for a new location.`,
  },
  {
    id: "02",
    photo: "Bee Swarm",
    photoImg: "bswarm02a.JPG",
    photographer: "Shelley Niemek",
    details: `Here, our London Farm Bee Keeper has located the swarm, and is putting the Honey bees in a box to put them in a new hive `,
  },
  {
    id: "03",
    photo: "Bee Swarm",
    photoImg: "bswarm11a.jpg",
    photographer: "Shelley Niemek",
    details: `The Bee Keeper has placed the queen in the box, so all the other bees will come along. The bee suit prevents the bees from stinging the beekeeper. Honey Bees are usually harmless, but some may be upset about having their moving plans changed.`,
  },
];
/*const fmlyFrmDay2022 = [
  { id: "01",
    photo: "Family Farm Day 2022",
    photoImg: "ffDay2022Poster.png",
    photographer:"London Heritage Farm Society",
    details: `Poster for the Family Farm Day`
  },
  { id: "02",
    photo: "Family Farm Day",
    photoImg: "ffDayPonyRide.png",
    photographer:"London Heritage Farm Society",
    details: `Pony Ride`
  },
  { id: "03",
    photo: "SOUTHLAWN GAZEBO",
    photoImg: "SouthLawnGazebo.jpg",
    photographer:"London Heritage Farm Society",
    details: `Wedding area`
  },
  { id: "04",
    photo: "ROOSTER",
    photoImg: "rooster.jpg",
    photographer:"Chris Penney",
    details: `Our Chickens are free range. The Rooster protects the flock by constantly roaming the area when they are outside. He will crow to warn of danger as well as to indicate safety.`
  },
];*/
const fmlyFrmDay2023 = [
  {
    id: "01",
    photo: "Family Farm Day 2023",
    photoImg: "f3-lineup.png",
    photographer: "London Heritage Farm Society",
    details: `Big lineup for Family Farm Day`,
  },
  {
    id: "02",
    photo: "Family Farm Day 2023",
    photoImg: "lawnBusy.png",
    photographer: "London Heritage Farm Society",
    details: `Between checking out the many attractions, visitors chilled out on the South Lawn`,
  },
  {
    id: "03",
    photo: "Family Farm Day 2023",
    photoImg: "ponyRide.png",
    photographer: "London Heritage Farm Society",
    details: `🐎 Pony rides led by Brendview Equestrian Centre were very popular`,
  },
  {
    id: "04",
    photo: "Family Farm Day 2023",
    photoImg: "homeCari.png",
    photographer: "London Heritage Farm Society",
    details: `Our Project Manager was always on the run ensuring everything went smoothly`,
  },
];
const historical = [
  {
    id: "01",
    photo: "THRESHING CREW",
    photoImg: "threshingcrew.jpg",
    photographer: "Richmond Archives",
    details: ``,
  },
  {
    id: "02",
    photo: "LONDON GRAND DAUGHTERS",
    photoImg: "daughtersv2.jpg",
    photographer: "Richmond Archives",
    details: ``,
  },
  {
    id: "03",
    photo: "LONDON FARM CIRCA 1908",
    photoImg: "farmv3.jpg",
    photographer: "Richmond Archives",
    details: ``,
  },
  {
    id: "04",
    photo: "HAY WAGON",
    photoImg: "haywagon.png",
    photographer: "Richmond Archives",
    details: `Bringing in the Hay`,
  },
  {
    id: "05",
    photo: "CHILD ON HORSE",
    photoImg: "childonhorse3.png",
    photographer: "Richmond Archives",
    details: `Child on horse at the London Farm`,
  },
];

function Gallery() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState();
  const [modalPhoto, setModalPhoto] = useState();
  const [modalDtls, setModalDtls] = useState();
  const [categories, resetCategories] = useState(historical);
  //const [toggler, setToggler] = useState(true);
  const [collOn, setCollOn] = useState(true);
  const [ctgrydspl, setCtgrydspl] = useState(true);
  const [dsply, setDsply] = useState(true);
  //const [dsplyLi, setDsplyLi] = useState("flex");

  /* const toggle = () => {
    setToggler(toggler => !toggler);
  };*/
  const changeCategory = (category) => {
    resetCategories(category);
    setCtgrydspl(false);
    /* setToggler(toggler => !toggler);*/
    /*console.log(toggler);*/
    /*setDsply(dsply=> !dsply);
    /*setDsply(true);*/
  };
  const dsplyCollOn = () => {
    {
      collOn ? setCollOn(true) : null;
    }
    setDsply(true);
    setCtgrydspl(true);
    console.log(dsply);
  };
  const listPhotos = categories.map((liImg) => (
    <li key={liImg.id}>
      <div
        className="glryItem"
        onClick={() => {
          setModalOpen(true);
          setModalPhoto(liImg.photo);
          setModalImage(liImg.photoImg);
          setModalDtls(liImg.details);
        }}
      >
        <img
          className="imgFmt"
          src={process.env.PUBLIC_URL + "/gallery/" + liImg.photoImg}
        ></img>
        <i className="photoName">{liImg.photo}</i>
        <i className="photoDetails">{liImg.photographer}</i>
      </div>
    </li>
  ));
  /*  <ul className={"glryUlFlex " + (dsply ? "glryClosed" : "glryOpen")}>*/

  return (
    <div className="glryTop">
      <div className="click4Enlrg">
        <p>Click on image to view ENLARGED picture</p>
      </div>
      <div className="mainCntnr">
        <div className="glryCtgry">
          <div className="gLogo">
            <i className="glryLogo">London</i>
            <i className="glryLogo">Farm</i>
            <i className="glryLogo">Gallery</i>
          </div>
          <div
            className="titleCollections"
            id="colllOn"
            onClick={() => dsplyCollOn("true")}
          >
            <i className="collTitle">COLLECTIONS</i>
            <i className="collPress">Press to Change Collections</i>
          </div>
          <div className="">
            <ul
              className={
                "glryCtgryUl " + (ctgrydspl ? "ctgryOpen" : "ctgryClosed")
              }
            >
              <li
                className="glryCtgryLi"
                onClick={() => changeCategory(photocontest)}
              >
                <i>Photo Contest Winners</i>
              </li>
              <li
                className="glryCtgryLi"
                onClick={() => changeCategory(beeswarm)}
              >
                <i>Bee Swarm</i>
              </li>
              <li
                className="glryCtgryLi"
                onClick={() => changeCategory(photos)}
              >
                <i>2023 Photos</i>
              </li>
              <li
                className="glryCtgryLi"
                onClick={() => changeCategory(fmlyFrmDay2023)}
              >
                <i>Family Farm Day 2023</i>
              </li>
              <li
                className="glryCtgryLi"
                onClick={() => changeCategory(historical)}
              >
                <i>Historical Photos</i>
              </li>
            </ul>
          </div>
        </div>
        <div className="">
          <ul
            className={
              "glryUlFlex " + (ctgrydspl ? "ctgryOpen" : "ctgryClosed")
            }
          >
            {listPhotos}
          </ul>
        </div>
        <div>
          {modalOpen && (
            <Modal
              setOpenModal={setModalOpen}
              modalPhoto={modalPhoto}
              modalImage={modalImage}
              modalDtls={modalDtls}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default Gallery;
