import React, { useState } from "react";
import NumberFormat from 'react-number-format';
//import CheckOut from "./CheckOut.js";
//import Statement from "./Statement";
import "./store.css";

function ShoppingCart() {
  const cartStorage = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
  //const [qtyChg, setQtyChg] = useState();
  //const [qty, setQty] = useState(1);
  //const [chckOut, setChckOut] = useState(false);
  //const rounded = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
  const [cartItems, setCartItems] = useState(cartStorage);
  console.log(cartItems);

  /* Calculate Subtotal */
  var amount = 0;
  var gstTax = 0;
  var pstTax = 0;
  var total = 0;
  for (let i = 0; i < cartItems.length; i++) {
    let xtndamnt = cartItems[i].price * cartItems[i].qty;
    amount += xtndamnt;

    /* switch to determine tax rates */
    let taxCode = cartItems[i].taxes;
    console.log(taxCode);
    switch (true) {
    case taxCode === "noTax":
      gstTax += xtndamnt * 0;
      pstTax += xtndamnt * 0;
      break;
    case taxCode === "fullTax":
      gstTax += xtndamnt * .05;
      pstTax += xtndamnt *.07;
      break;
    case taxCode === "noPst":
      gstTax += xtndamnt * .05;
      pstTax += xtndamnt * 0;
      break;
    default:
      console.log("tax switch error");
    }
    /*Round taxes */
    const rounded = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let tmpTax = (rounded(pstTax, 2));
    pstTax = tmpTax;
    console.log(pstTax);
    tmpTax = (rounded(gstTax, 2));
    gstTax = tmpTax;
    console.log(gstTax);
    total = amount + pstTax + gstTax;
  }
  const qtyChange = (qty, id) => {
    console.log("qty changed");
    //setQtyChg(qty);
    console.log('qty: ' + qty);
    /* determine the index for cartItem record by id */
    function getIndex(id) {
      return cartItems.findIndex(obj => obj.id === id);
    }
    let cartIndx = getIndex(id);
    console.log('id: ' + id);
    /* replace cartItem.qty with new qty */
    console.log('item tb repl: ' + cartItems[cartIndx].qty);
    cartItems[cartIndx].qty = qty;
    console.log('replaced: ' + cartItems[cartIndx].qty);
    localStorage.setItem('cart', JSON.stringify(cartItems));
  };
  const remove = (id) => {
    function getIndex(id) {
      return cartItems.findIndex(obj => obj.id === id);
    }
    let idx = (getIndex(id));
    console.log(idx);
    //assign file to temp
    let temp = [...cartItems];
    //remove item by index
    temp.splice(idx, 1);
    setCartItems(temp);
    localStorage.setItem('cart', JSON.stringify(cartItems));
    console.log(cartItems);
  };

  const cartList = cartItems.map((cartLi) =>
    <li className="licntrl" key={cartLi.id}>
      <div className="cartItem">
        <div className="cartLeftSide">
          <i className="cartLiId">{cartLi.id}</i>
          <img className="cartLiImg" src={cartLi.prodImg} alt=""></img>
        </div>
        <div className="cartRightSide">

          <i className="cartName">{cartLi.name}</i>
          <div>
            <div className="qtyCntnr"></div>
            {/*<label htmlFor="cartQty">Qty:</label>*/}
            <label className="cartQtyTitle">Qty:</label>

            <select className="cartQtyAmnt" onChange={(e) => qtyChange(e.target.value, cartLi.id)}>
              <option value= "1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div>
            <i className="cartPriceTitle">Price:</i>
            <i className="cartPrice">
              <NumberFormat value={cartLi.price * cartLi.qty} displayType={'text'} thousandSeparator={true} prefix={'$'}
                decimalScale={2} fixedDecimalScale={true}/>
            </i>
          </div>
          <p className="deleteBtn" onClick={() => remove(cartLi.id)}>Remove</p>

        </div>
      </div>
    </li>);

  const checkOut = () => {
    console.log('at checkout');
    //setChckOut(true); commented out as chkout not done
  };

  return (
    <div className="cartBkgnd">
      <div className="i-top">
        <div className="cartHdng">
          <p>Shopping Cart</p>
        </div>
        <div className="cartFlex1">
          <div className="cartItems">
            <ul>
              {cartList}
            </ul>
          </div>
          <div className="cartCheckout">
            <div className="subttlPosition">
              <i className="cartSubTitle">Subtotal:    </i>
              <i className="cartSubtotal">
                <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={'$'}
                  decimalScale={2} fixedDecimalScale={true} />
              </i>
            </div>
            <div>
              <i className="cartSubTitle">gst:    </i>
              <i className="cartSubtotal">
                <NumberFormat value={gstTax} displayType={'text'} thousandSeparator={true} prefix={'$'}
                  decimalScale={2} fixedDecimalScale={true} />
              </i>
            </div>
            <div>
              <i className="cartSubTitle">pst:    </i>
              <i className="cartSubtotal">
                <NumberFormat value={pstTax} displayType={'text'} thousandSeparator={true} prefix={'$'}
                  decimalScale={2} fixedDecimalScale={true} />
              </i>
            </div>
            <div>
              <i className="cartSubTitle">Total:    </i>
              <i className="cartSubtotal">
                <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={'$'}
                  decimalScale={2} fixedDecimalScale={true} />
              </i>
            </div>
            <div className="btn-center">
              <button className="btn-order" onClick={checkOut}>Proceed to Checkout</button>
            </div>
          </div>

        </div>
        <div>
          {/* {chckOut && <CheckOut
						amount={total} />} */}
        </div>
      </div>
    </div>
  );
}
export default ShoppingCart;