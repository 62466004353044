import React from "react";
import "./home.css";
/*import lhfmap from "../img/lhf-map.png";*/
//import farmhouse from "../img/explore/farmhouse200x113.jpg";

function HomeBtns(props) {
  /*<li
          className="homeBtnsItems"
          style={{
            backgroundImage: `linear-gradient(
						rgba(0,0,0, 0.2),
						rgba(0,0,0, 0.2)
					  ), url(${farmhouse})`,
          }}
          onClick={() => props.chgComponent("planvisit")}
        >
          </li> */
  /*<li
          className="homeBtnsItems"
          onClick={() => props.chgComponent("contactus")}
        >
          <i className="iBtnName">Contact Us</i>
        </li>*/
  return (
    <div>
      <ul className="homeBtns">
        <li
          className="homeBtnsItems"
          onClick={() => props.chgComponent("planvisit")}
        >
          <i className="iBtnName">Plan a Visit</i>
        </li>
        <li
          className="homeBtnsItems"
          onClick={() => props.chgComponent("honeyBees1")}
        >
          <i className="iBtnName">Honey Bees pt1</i>
        </li>
      </ul>
    </div>
  );
}
export default HomeBtns;
