import React from "react";
import ReactPlayer from 'react-player/youtube';
import {Carousel} from 'react-responsive-carousel';
import "./explore.css";


function ExpHeritageGardens () {

  return(
    <div className="expArticle">
      <div>
        <p className="expTitle">The Heritage Gardens</p>
      </div>
      <div className="expCarousel">

        <Carousel
          showIndicators={true}
          infiniteLoop={true}
          showThumbs={false}
          showArrows={true}
          useKeyboardArrows={true}
        >
          <div>
            <img src={process.env.PUBLIC_URL + "heritage920x.jpg"} alt="" />
            <p className="legend">The Heritage Gardens</p>
          </div>

          <div>
            <img src={process.env.PUBLIC_URL + "heritage028x.jpg"} alt="" />
            <p className="legend">Heritage gardens</p>
          </div>
          <div>
            <img src={process.env.PUBLIC_URL + "heritage029x.jpg"} alt="" />
            <p className="legend">Heritage gardens</p>
          </div>
          <div>
            <img src={process.env.PUBLIC_URL + "heritage026x.jpg"} alt="" />
            <p className="legend">Heritage gardens</p>
          </div>
          <div>
            <img src={process.env.PUBLIC_URL + "heritage024x.jpg"} alt="" />
            <p className="legend">Heritage gardens</p>
          </div>

        </Carousel>
      </div>
      <div className="expContent">
        <p>Welcome to our Heritage Gardens!</p>

        <p>The Heritage Gardens are planned, planted and tended by London Heritage Farm Society volunteers (The City of Richmond maintains the rest of the grounds around the Farmhouse).</p>

        <p>The Heritage Gardens feature bushes, berries, herbs, annuals, perennials, bulbs, trees and vines, most of which could be found in gardens at the turn of the 20th century.  The Heritage Gardens are in the style of an English country garden where something is in bloom from early Spring until the Fall frosts.  Careful plantings are used and natural predators are encouraged in the organic prevention and control of disease and pests.</p>

        <p>Flat, crushed limestone paths and garden benches provide easy access and resting places from which visitors can enjoy the beauty and fragrances of the Heritage Gardens.  The garden paths are wheelchair and walker accessible.</p>
      </div>
      <div className="exp-video">
        <div className="expSubHdng">
          <p>Fruit trees of London Farm</p>
        </div>

        <div className="player-wrapper">

          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=-yBZmdcD-vU"
            width='100%'
            height='100%'
            light={false}
            controls={true}
          />
        </div>
        <div className="expSubHdng">
          <p>Flowers of London Farm</p>
        </div>

        <div className="player-wrapper">

          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=lPqMCXWYNEM"
            width='100%'
            height='100%'
            light={false}
            controls={true}
          />
        </div>
      </div>
    </div>
  );
}
export default ExpHeritageGardens;