import React, { useState } from "react";
import NumberFormat from 'react-number-format';
import "./store.css";

import china from "../img/items/hummingbirdteapotBtn.png";
import misc from "../img/items/mellowsoap-lemonverbena.png";
import teabtn from "../img/items/london-lady-tea-btn.png";
import jambtn from "../img/items/strawberry-jam-btn.png";

const Jam = [
  { id: 401, name: "London Lady Jam Strawberry", prodImg: "london-lady-strawberry-jam.png", price: "9.00", taxes: "fullTax", qty: 1, details: `Crafted by London Farm from fresh local natural ingredients, Non-GMO, No preservatives, Gluten Free` },
  { id: 402, name: "London Lady Jam Strawberry Rhubarb", prodImg: "london-lady-strawberry-rhubarb-jam.png", price: "9.00", taxes: "fullTax", qty: 1, details: `Made by London Farm from fresh local natural ingredients` },
  { id: 403, name: "London Lady Jam Apple Grape", prodImg: "london-lady-jam.png", price: "9.00", taxes: "fullTax", qty: 1, details: `Made by London Farm from fresh local natural ingredients` },
  { id: 404, name: "London Lady Jam Strawberry Pear Raspberry", prodImg: "london-lady-jam.png", price: "9.00", taxes: "fullTax", qty: 1, details: `Made by London Farm from fresh local natural ingredients` },
  { id: 405, name: "London Lady Jam Strawberry Blackberry", prodImg: "london-lady-jam.png", price: "9.00", taxes: "fullTax", qty: 1, details: `Made by London Farm from fresh local natural ingredients` },
  { id: 407, name: "London Lady Jam Raspberry Pear", prodImg: "london-lady-jam.png", price: "9.00", taxes: "fullTax", qty: 1, details: `Made by London Farm from fresh local natural ingredients` },
  { id: 409, name: "London Lady Jam Blueberry", prodImg: "london-lady-jam.png", price: "9.00", taxes: "fullTax", qty: 1, details: `Made by London Farm from fresh local natural ingredients` },
  { id: 408, name: "London Lady Tomato Pickle Relish", prodImg: "london-lady-jam.png", price: "9.00", taxes: "fullTax", qty: 1, details: `Made by London Farm from fresh local natural ingredients` }
];
const Teas = [
  { id: 104, name: "London Lady Tea Leaves Tin ", prodImg: "london-lady-tea.png", price: "15.95", taxes: "fullTax", qty: 1, details: `(Tea Tin - loose) A Rich Blend of Black Tea, Lemon Balm leaves, Strawberry Pieces, Calendula and Sunflower Petals` },
  { id: 107, name: "London Lady Tea 10 Tea Bags", prodImg: "london-lady-tea.png", price: "4.95", taxes: "fullTax", qty: 1, details: `(10 Tea Bags per package) A Rich Blend of Black Tea, Lemon Balm leaves, Strawberry Pieces, Calendula and Sunflower Petals` },
  { id: 108, name: "London Lady Tea Leaves", prodImg: "tealoosesmv2.png", price: "4.95", taxes: "fullTax", qty: 1, details: `(10 Tea Bags per package) A Rich Blend of loose Black Tea, Lemon Balm leaves, Strawberry Pieces, Calendula and Sunflower Petals` },
  { id: 105, name: "Tea Brew filters", prodImg: "teabrew-filters.png", price: "6.95", taxes: "fullTax", qty: 1, details: `(100 per pkg.) Single-use filters for loose tea` },
  { id: 106, name: "Tea Bag Caddy", prodImg: "tea-bag-caddy.png", price: "3.99", taxes: "fullTax", qty: 1, details: `Tea Pot shape, multiple colours` }
];

const Misc = [
  { id: 201, name: "Mellow Soap - lemon verbena", prodImg: "mellowsoap-lemonverbena.png", price: "5.00", taxes: "fullTax", qty: 1, details: `* ` },
  { id: 202, name: "Mellow Soap - Mint", prodImg: "mellowsoap-mint.png", price: "5.00", taxes: "fullTax", qty: 1, details: `* ` },
  { id: 203, name: "Mellow Soap - Ocean Mint", prodImg: "mellowsoap-oceanmint.png", price: "5.00", taxes: "fullTax", qty: 1, details: `* ` },
  { id: 204, name: "Blue Ace Night Light", prodImg: "nightlight.png", price: "22.99", taxes: "fullTax", qty: 1, details: `* ` },

];
const China = [
  { id: 301, name: "Springtime Teapot", prodImg: "springtimeTeapot.png", price: "39.95", taxes: "fullTax", qty: 1, details: ` Porcelain Teapot in a Gift Box 1000 ML` },
  { id: 306, name: "Springtime Mug", prodImg: "springtimeMug.png", price: "15.95", taxes: "fullTax", qty: 1, details: `Porcelain Mug in a Gift Box 300 ML`},
  { id: 307, name: "Owl Teapot", prodImg: "owlTeapot.png", price: "39.95", taxes: "fullTax", qty: 1, details: `Porcelain Teapot in a Gift Box 1000 ML` },
  { id: 308, name: "Owl Mug", prodImg: "owlMug.png", price: "15.95", taxes: "fullTax", qty: 1, details: `Porcelain Mug in a Gift Box 300 ML` },
  { id: 309, name: "Hummingbird Teapot", prodImg: "hummingbirdteapot.png", price: "22.95", taxes: "fullTax", qty: 1, details: `Porcelain Teapot in Gift Box - 900 ML` },
  { id: 311, name: "Hummingbird Mug", prodImg: "hummingbirdmugv2.png", price: "9.95", taxes: "fullTax", qty: 1, details: `Porcelain Mug - 330 ML` },
  { id: 302, name: "Swallow Teapot", prodImg: "sparrow-teapot.png", price: "22.95", taxes: "fullTax", qty: 1, details: `Porcelain Teapot in Gift Box - 900 ML` },
  { id: 303, name: "Swallow Mug", prodImg: "sparrow-mug.png", price: "9.95", taxes: "fullTax", qty: 1, details: ` Porcelain Mug - 330 ML`},
  { id: 304, name: "Golden Feather Mug", prodImg: "goldenfeathermug.png", price: "17.95", taxes: "fullTax", qty: 1, details: `Porcelain Mug - Includes a circular gift box` },
  { id: 310, name: "Chickadee Teapot", prodImg: "sparrow-teapot.png", price: "22.95", taxes: "fullTax", qty: 1, details: `Porcelain Teapot - ON BACKORDER - 900 ML` },
  { id: 305, name: "Chickadee Mug", prodImg: "chickadeeMug.png", price: "17.95", taxes: "fullTax", qty: 1, details: `Porcelain Mug - 330 ML` }
];

function Products(props) {
  const [products, resetProds] = useState(China);
  //const [itemSelected, setSelected] = useState();
  //const [displayToggle, setDisplayToggle] = useState(false);
  console.log(products);
  /*useEffect(() => {
		resetProds(props.category);
	},[products]);*/

  /*const msgDisplay = () => {
		setDisplayToggle(displayToggle=>!displayToggle);
		console.log(displayToggle);
	};*/

  const changeCategory = (category) => {
    resetProds(category);
    console.log(category);
  };
  const selectItem = (id) => {
    /* determine the index for product record by id */
    function getIndex(id) {
      return products.findIndex(obj => obj.id === id);
    }
    //let prodId = getIndex(id);
    console.log(getIndex(id));
    let tmp = (products[getIndex(id)]);
    //setSelected(tmp);
    console.log(tmp);
    props.prodDetails(tmp);
    // render ProdDetails with props for selected product
  };

  const listProducts = products.map((product) => (
    <li className="storeProducts" key={product.id}
      onClick={() => selectItem(product.id)}>
      <i className="productId">{product.id}</i>
      <div className="productTop">
        <img className="img-ctr" src={process.env.PUBLIC_URL + '/store/'+ product.prodImg} alt="product"></img>
      </div>
      <p className="productName">{product.name}</p>
      <div className="detPrice">
        <NumberFormat value={product.price} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}
          fixedDecimalScale={true}
          className="price" />
      </div>

    </li>));
  /*<div>
				<i className="addToList" onClick={(e) => selectItem(product.id)}
				> Details</i>
			</div>*/
  return (
    <div className="storeBkgnd">
      {/* <div>
        <i className="msgToCart" style={{display: displayToggle  ? 'inline' : 'none'}}
        >Sent to Cart</i>
  </div>*/}
      <div className="storeFlexTop">
        <div className="storeCatgSize">
          <ul className="storeCategories">

            <li className="storeCategoryItems" style={{
              backgroundImage:
								`linear-gradient(
									rgba(0,0,0, 0.3),
									rgba(0,0,0, 0.3)
								  ), url(${china})`
            }}

            onClick={() => changeCategory(China)}><i className="storCtgBtn">China</i>
            </li>

            <li className="storeCategoryItems" style={{
              backgroundImage:
							`linear-gradient(
								rgba(0,0,0, 0.3),
								rgba(0,0,0, 0.3)
							  ), url(${jambtn})`

            }}
            onClick={() => changeCategory(Jam)}><i className="iDescript">London Lady Jams</i>
            </li>

            <li className="storeCategoryItems" style={{
              backgroundImage:
							`linear-gradient(
								rgba(0,0,0, 0.3),
								rgba(0,0,0, 0.3)
							  ), url(${teabtn})`
            }}
            onClick={() => changeCategory(Teas)}><i className="iDescript">London Lady Teas</i>
            </li>


            <li className="storeCategoryItems" style={{
              backgroundImage:
							`linear-gradient(
								rgba(0,0,0, 0.3),
								rgba(0,0,0, 0.3)
							  ), url(${misc})`
            }}
            onClick={() => changeCategory(Misc)}><i className="storCtgBtn">Misc</i>
            </li>
          </ul>
        </div>
        <div>
          <ul className="store-Flex2">
            {listProducts}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Products;