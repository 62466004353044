import React from "react";
import ReactPlayer from "react-player/youtube";
//import expHistryImg1 from "../explore/img/chickens2.jpeg";
import rooster1 from "../img/explore/rooster1.jpeg";
//import rooster1chickensMenu from "../img/explore/chickens3.jpg";

function ExpChickens() {
  return (
    <div className="expArticle">
      <div>
        <p className="expTitle">Meet our happy flock of Chickens</p>
      </div>
      <div>
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=uS1UMvmafu4"
            fs="0"
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
        <div className="expContent">
          <p className="expSubHdng">Chicken Facts</p>

          <p>
            Chickens are one of the most common and widespread domestic animals,
            with a total population of 23.7 billion and counting. There are more
            chickens in the world than any other bird. Most of them are egg and
            meat producing hens, as female chickens are called. Unfortunately,
            most of the industrial hens are kept in less than pleasant
            circumstances.
          </p>

          <p>
            Domestic chickens depend on our best care and deserve our respect.
            They will reward us with companionship and allow us a chance to
            discover their individual personalities. While many do not cuddle
            much, they respond to and follow their handlers, as well as show
            affection.
          </p>

          <p className="expSubHdng">The Rooster</p>

          <p>
            The rooster is the boss of the brood; at least he thinks so. The
            rooster keeps an eye on his hens and protects them. He will step in
            if they squabble too much, and he never rests before checking if
            every hen is OK and all is safe. When a rooster finds food, he will
            call the other chickens to eat first. He does this by clucking in a
            high pitch as well as picking up and dropping the food. He will not
            eat before the hens have. A true gentleman!
          </p>
          <img className="exp-img-responsive" src={rooster1} alt="rooster" />

          <p>
            No farm feels like a farm without a rooster crowing! Crowing sends a
            territorial signal, but roosters may also crow in response to sudden
            dangers within their surroundings, or just to let everybody know
            that he is happy to be in charge. He is proud, beautiful and a bit
            of a show off. Though his most important task is to fertilize eggs
            and to protect his hens, hens can live perfectly well and lay eggs
            without a rooster. Obviously, they are much happier with a handsome
            rooster around.
          </p>

          <p className="expSubHdng">The Hens</p>

          <p>
            Hens eat and chit chat the whole day. They need a lot of food to
            make an egg. Hens are also very busy to keep up with their ranking
            place in the group. Who is number 1 and who is the last in the
            ranking order? Only they know who is who.
          </p>

          <figure>
            <img
              className="exp-img-responsive"
              src={process.env.PUBLIC_URL + "curioushen.jpg"}
              alt="curious hen"
            ></img>

            <figcaption>Curious hen</figcaption>
          </figure>

          <p>
            In their free time chickens like to take a dust bath, which helps to
            keep their feathers clean. Once a year a hen will go into molt,
            losing part of her feathers, which does not look very nice. After
            about 2 years the average hen stops laying eggs and goes into
            retirement. When well taken care of, she can live for about 6 years.
          </p>

          <p className="expSubHdng">The Egg</p>

          <p>
            The diet of laying hens affects the taste and nutritional quality of
            eggs. Hens which mostly forage for their own food, produce eggs that
            are relatively more enriched in omega-3 fatty acids when compared to
            those of the unfortunate cage-raised chickens. The food a hen eats
            determines the taste of an egg and the color of the yoke. It will
            take about 26 to 27 hours and a lot of energy for a hen to produce
            an egg. The actual laying of an egg can take up more than an hour.
            That is why you can hear hens cluck loudly from endless joy after
            all is done.
          </p>

          <p>Robert Berkhout.</p>
        </div>
      </div>
    </div>
  );
}
export default ExpChickens;
